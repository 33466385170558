<template>
	<div>
		<b-row>
			<b-col lg="6" md="6" sm="6" class="heading-title">
				<h4>Historical Summary</h4>
				<b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
					<b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
					<b-breadcrumb-item to="/historical_booking">Historical</b-breadcrumb-item>
					<b-breadcrumb-item active>View</b-breadcrumb-item>
				</b-breadcrumb>
			</b-col>
			<b-col lg="6" md="6" sm="6" class="btns-header"> </b-col>
		</b-row>
		<br>
		<b-row>
			<b-col lg="12" md="12" sm="12" class="heading-title" ref="msgContainer">
				<b-button @click="goBack()"  class=" btn-venuecolor btn-sm mb-2 mt-1">
					<i class="mdi mdi-arrow-left "></i> Back
				</b-button>
				
				<b-card class="mb-4 w-100">
				<b-row>
					<b-col lg="12" md="12" sm="12" class="heading-title" ref="msgContainer">
						
						<h3 class="text-center">  Historical  Summary </h3>
						<div class="d-flex justify-content-between">
							
							<div> 
								Order date : <span class="text-danger fs-4"> {{date_format(booked_on)}}</span>
							</div>
							<div class="mt-2"> 
								
								<div>Booking Ref. No.. <span class="text-danger fs-4">{{ ref_id }}</span></div>
								<div>Booking ID. <span class="text-danger fs-4">{{ Book_id }}</span></div>
								
							</div>
						</div>
						<hr>
						
						<b-row>
							<div class="col-lg-12">
								<table class="w-100">
									<tbody>
										<tr>
											<td><b>Venue Name</b></td>
											<td class="px-3">:</td>
											<td>{{ child_venue_name }}</td>
											
											<td class="text-left"><b>Booking Date</b></td>
											<td class="px-3">:</td>
											<td class="text-left">{{ date_format(from_date) }}</td>
										</tr>
										<tr>
											<td><b>Shift</b></td>
											<td class="px-3">:</td>
											<td>{{ booked_shift_type }} Shift</td>
											<td><b>Timings</b></td>
											<td class="px-3">:</td>
											<td>{{ convert_24_12hour(from_time) }} to {{ convert_24_12hour(to_time) }}</td>
										
										</tr>
										<tr>
											<td><b>Event type</b></td>
											<td class="px-3">:</td>
											<td><span v-if="event_name != ''">{{ event_name }}</span></td>
											
											<td><b>Guest capacity </b></td>
											<td class="px-3">:</td>
											<td>{{ guest_capacity }}</td>
											
											
										</tr>
										
									</tbody>
								</table>
							</div>
							
						</b-row>	
						<hr>
						
						<b-row>	
							
							<div class="col-lg-12">
								<h4> Customer details</h4>
								<table class="w-100">
									<tbody>

										<tr v-if="this.venue_booking_details.booking_for_self == 0">
											<td><b> Representative name</b></td>
											<td class="px-3">:</td>
											<td>{{ this.venue_booking_details.booking_for_name }}</td>
											
											<td class="text-left"><b>Booking for relationship </b></td>
											<td class="px-3">:</td>
											<td class="text-left">{{ this.venue_booking_details.booking_for_relationship }}</td>
											
											<td class="text-left"><b>Booking for mobile no</b></td>
											<td class="px-3">:</td>
											<td class="text-left">{{ this.venue_booking_details.booking_for_mobile_no }}</td>
										</tr>

										<tr>
											<td><b> Customer name</b></td>
											<td class="px-3">:</td>
											<td>{{ billing_first_name }}</td>
											
											<td class="text-left"><b>Email</b></td>
											<td class="px-3">:</td>
											<td class="text-left">{{ billing_email }}</td>
											
											<td class="text-left"><b>Phone</b></td>
											<td class="px-3">:</td>
											<td class="text-left">{{ billing_phone }}</td>
										</tr>
										<tr>
											<td><b>Address</b></td>
											<td class="px-3">:</td>
											<td>{{ billing_address }} </td>
											
										</tr>
										
										
									</tbody>
								</table>
							</div>
						
						</b-row>
						<span v-if="informations">
						<hr v-if=" informations.billing_bride_name!=null || informations.billing_bridegroom!=null || informations.billing_bridelocation!=null || informations.billing_bridegroomlocation!=null || informations.billing_caterer!=null || informations.booking_decorator!=null || informations.booking_music!=null">
						<h4 v-if=" informations.billing_bride_name!=null || informations.billing_bridegroom!=null || informations.billing_bridelocation!=null || informations.billing_bridegroomlocation!=null || informations.billing_caterer!=null || informations.booking_decorator!=null || informations.booking_music!=null"  > Information</h4>
						<b-row>
							
							<div class="col-lg-12">
								<table class="w-100" >
									<tbody>
										<tr>
											<td v-if="informations.billing_bride_name!=null"><b> Bride Name</b></td>
											<td v-if="informations.billing_bride_name!=null" class="px-3">:</td>
											<td v-if="informations.billing_bride_name!=null">{{ informations.billing_bride_name }}</td>
											
											<td v-if="informations.billing_bridegroom!=null" class="text-left"><b> Bridegroom Name</b></td>
											<td v-if="informations.billing_bridegroom!=null" class="px-3">:</td>
											<td v-if="informations.billing_bridegroom!=null" class="text-left">{{ informations.billing_bridegroom }}</td>
											
											<td v-if="informations.billing_bridelocation!=null" class="text-left"><b> Bride City </b></td>
											<td v-if="informations.billing_bridelocation!=null" class="px-3">:</td>
											<td v-if="informations.billing_bridelocation!=null" class="text-left">{{ informations.billing_bridelocation }}</td>
											
											
										</tr>
										<tr>
											
											
											<td v-if="informations.billing_bridegroomlocation!=null"><b>Bridegroom City</b></td>
											<td v-if="informations.billing_bridegroomlocation!=null" class="px-3">:</td>
											<td v-if="informations.billing_bridegroomlocation!=null">{{ informations.billing_bridegroomlocation }} </td>
											
											<td v-if="informations.billing_caterer!=null" class="text-left"><b> Caterer Name </b></td>
											<td v-if="informations.billing_caterer!=null" class="px-3">:</td>
											<td v-if="informations.billing_caterer!=null" class="text-left">{{ informations.billing_caterer }}</td>
											
											<td v-if="informations.booking_decorator!=null" class="text-left"><b> Decorator Name </b></td>
											<td v-if="informations.booking_decorator!=null" class="px-3">:</td>
											<td v-if="informations.booking_decorator!=null" class="text-left">{{ informations.booking_decorator }}</td>
											
										</tr>
										
										<tr>
											
											
											<td v-if="informations.booking_music!=null"><b>Music person Name</b></td>
											<td v-if="informations.booking_music!=null" class="px-3">:</td>
											<td v-if="informations.booking_music!=null">{{ informations.booking_music }} </td>
											
										</tr>
										
										
									</tbody>
								</table>
							</div>
							
						
							
						</b-row> 
					</span>
						<hr>
						<b-row>	
							
							<b-col md="6">
								<h4> Payment Details</h4>
								<b-list-group>
  <b-list-group-item class="d-flex justify-content-between align-items-center">
	{{areceipt_no}}  <span>{{date_format(a_pay_date)}}</span> <span>{{a_pay_mode}}</span>  <span>{{numberWithCommas(a_amount)}}</span>
  </b-list-group-item>

  <b-list-group-item class="d-flex justify-content-between align-items-center">
    {{freceipt_no}}  <span>{{date_format(f_pay_date)}}</span> <span>{{f_pay_mode}}</span>  <span>{{numberWithCommas(f_amount)}}</span>
  </b-list-group-item>

  <b-list-group-item class="d-flex justify-content-between align-items-center">
	{{sreceipt_no}}  <span>{{date_format(s_pay_date)}}</span> <span>{{s_pay_mode}}</span>  <span>{{numberWithCommas(s_amount)}}</span>
  </b-list-group-item>
</b-list-group>
								
					
							</b-col>	
							
							<b-col md="1" style="border-right:1px solid red"></b-col>
							<b-col md="4" class="text-center">
								
								<b-row>
									<b-col md="2" ></b-col>
									<b-col md="10" >
										<h4 class="text-left"> Pricing Summary</h4>
										<ul class="custom-amount-summary_1">
											<li>
												<div class="row mt-1 mb-1">
													<div class="col-md-7 text-left "> 
														Base Price
													</div>
													<div class="col-md-1 fw-bold text-right" > 
														<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
													</div>
													<div class="col-md-4 fw-bold text-right" > 
														{{  numberWithCommas( autocalculation(total_booking_price)) }}
													</div>
												</div>	
											</li>
											
											<li  >
												<div class="row mt-1 mb-1">
										<div class="col-md-7 text-left "> 
											<b>Add-on :</b> 
										</div>
										<div class="col-md-1 fw-bold text-right" > 
											<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
													</div>
										<div class="col-md-4 text-right"  > 
											{{ numberWithCommas(addOn_total) }}
										</div>
									</div>	
								</li>
								<hr class="summary-hr">
								<li  >
									<div class="row mt-1 mb-1">
										<div class="col-md-7 text-left "> 
											<b> Total Base price</b> 
										</div>
										<div class="col-md-1 fw-bold text-right" > 
											<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
													</div>
										<div class="col-md-4 text-right" > 
											
												{{ numberWithCommas(add_sub_total) }}
										
										</div>
									</div>	
								</li>
								<li >
									<div class="row mt-1 mb-1">
										<div class="col-md-7 text-left "> 
											Discount Applied
										</div>
										<div class="col-md-1 fw-bold text-right" > 
											<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
													</div>
										<div class="col-md-4  text-right"> 
											{{ numberWithCommas(this.venue_booking_details.applied_discount) }}
										</div>
									</div>	
								</li> 
								<hr class="summary-hr">
								<li >
									<div class="row mt-1 mb-1">
										<div class="col-md-7 text-left "> 
											<b>Sub total</b>
										</div>
										<div class="col-md-1 fw-bold text-right" > 
											<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
													</div>
										<div  class="col-md-4 text-right"> 
											
												{{numberWithCommas( after_discount) }}
										
										</div>
									</div>	
								</li>
								<li>
									<div class="row mt-1 mb-1">
										<div class="col-md-7 text-left "> 
											GST (18%)
										</div>
										<div class="col-md-1 fw-bold text-right" > 
											<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
													</div>
										<div class="text-right col-md-4"> 
											{{ numberWithCommas(Math.round(afterTax)) }}
										</div>
									</div>	
								</li>
								<hr class="summary-hr">
								<li>
									<div class="row mt-1 mb-1">
										<div class="col-md-7 text-left "> 
											Total
										</div>
										<div class="col-md-1 fw-bold text-right" > 
											<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
													</div>
										<div class="col-md-4 fw-bold fs-4  text-right"> 
											<span >
												{{ numberWithCommas(Math.round(total_amount)) }}
											</span>
										</div>
									</div>	
								</li>
								
	
											
										</ul>
										Payment mode : {{ this.makeUpperCase(this.venue_booking_details.payment_mode) }}
									</b-col>
								</b-row>
							</b-col>	
						</b-row>
						<hr>
						<div v-if="this.special_request != null">
							<h5>Special Requests</h5>
							<p v-html="this.special_request"></p>
						</div>
					
					</b-col>
				</b-row>
			</b-card>	


				
				
			</b-col>
		</b-row>	
		
		
		
		
		
		
		
		
		<br />
		<!-- <b-card class="mb-4 w-100">
			<div class="text-left">
				<b-button @click="goBack()" variant="primary" class="mt-2">
					Back
				</b-button>
				&nbsp;
			</div>
			<b-row>
				
				<b-col cols="6">
					<b-row class="py-2 custon_border align-items-center">
						<b-col sm="4" class="text-left">
							<label class="fw-medium mb-0" for="nname2"> Venue Name :</label>
						</b-col>
						<b-col sm="8">
							<span>
								{{ child_venue_name }}
							</span>
						</b-col>
					</b-row>
				</b-col>
				<b-col cols="6">
					<b-row class="py-2 custon_border align-items-center">
						<b-col sm="4" class="text-left">
							<label class="fw-medium mb-0" for="nname2"> Customer Name:</label>
						</b-col>
						<b-col sm="8">
							<span>
								{{ customer_name }}
							</span>
						</b-col>
					</b-row>
				</b-col>
				<b-col cols="6">
					<b-row class="py-2 custon_border align-items-center">
						<b-col sm="4" class="text-left">
							<label class="fw-medium mb-0" for="nname2">Booked On-Date:</label>
						</b-col>
						<b-col sm="8">
							<span>
								{{ booked_on }}
							</span>
						</b-col>
					</b-row>
				</b-col>
				<b-col cols="6">
					<b-row class="py-2 custon_border align-items-center">
						<b-col sm="4" class="text-left">
							<label class="fw-medium mb-0" for="nname2">
								Booking For - Date:</label
							>
						</b-col>
						<b-col sm="8">
							<span> {{ from_date }} to {{ to_date }} </span>
						</b-col>
					</b-row>
				</b-col>
				
				<b-col cols="6">
					<b-row class="py-2 custon_border align-items-center">
						<b-col sm="4" class="text-left">
							<label class="fw-medium mb-0" for="nname2"> Timings :</label>
						</b-col>
						<b-col sm="8">
							<span> {{ from_time }} to {{ to_time }} </span>
						</b-col>
					</b-row>
				</b-col>
				
				
				<b-col cols="6">
					<b-row class="py-2 custon_border align-items-center">
						<b-col sm="4" class="text-left">
							<label class="fw-medium mb-0" for="nname2">Amount:</label>
						</b-col>
						<b-col sm="8">
							<span> Rs. {{ total_booking_price }}/- </span>
						</b-col>
					</b-row>
				</b-col>
				
				<b-col cols="6" v-if="this.venue_booking_details.applied_discount != null">
					<b-row class="py-2 custon_border align-items-center">
						<b-col sm="4" class="text-left">
							<label class="fw-medium mb-0" for="nname2">Applied discount:</label>
						</b-col>
						<b-col sm="8">
							<span> Rs. {{ this.venue_booking_details.applied_discount }}/- </span>
						</b-col>
					</b-row>
				</b-col>
				
				<b-col cols="6">
					<b-row class="py-2 custon_border align-items-center">
						<b-col sm="4" class="text-left">
							<label class="fw-medium mb-0" for="nname2">Payment mode:</label>
						</b-col>
						<b-col sm="8">
							<span>{{ this.makeUpperCase(this.venue_booking_details.payment_mode) }}</span>
						</b-col>
					</b-row>
				</b-col>
				
				<b-col cols="6">
					<b-row class="py-2 custon_border align-items-center">
						<b-col sm="4" class="text-left">
							<label class="fw-medium mb-0" for="nname2">Payment:</label>
						</b-col>
						<b-col sm="8">
							<span v-if="this.venue_booking_details.booking_type == 1">Full</span>
							<span v-if="this.venue_booking_details.booking_type == 2">Blocked</span>
							<span v-if="this.venue_booking_details.booking_type == 3">Advance</span>
						</b-col>
					</b-row>
				</b-col>
				
				<b-col cols="6" v-if="this.venue_booking_details.booking_type == '2'">
					<b-row class="py-2 custon_border align-items-center">
						<b-col sm="4" class="text-left">
							<label class="fw-medium mb-0" for="nname2">Block date:</label>
						</b-col>
						<b-col sm="8">
							<span>{{ this.venue_booking_details.block_date }}</span>
						</b-col>
					</b-row>
				</b-col>
				
				<b-col cols="6">
					<b-row class="py-2 custon_border align-items-center">
						<b-col sm="4" class="text-left">
							<label class="fw-medium mb-0" for="nname2">
								Billing Email :</label
							>
						</b-col>
						<b-col sm="8">
							<span> {{ billing_email }} </span>
						</b-col>
					</b-row>
				</b-col>
				
				<b-col cols="6">
					<b-row class="py-2 custon_border align-items-center">
						<b-col sm="4" class="text-left">
							<label class="fw-medium mb-0" for="nname2">
								Billing Phone :</label
							>
						</b-col>
						<b-col sm="8">
							<span> {{ billing_phone }} </span>
						</b-col>
					</b-row>
				</b-col>
				
				<b-col cols="6">
					<b-row class="py-2 custon_border align-items-center">
						<b-col sm="4" class="text-left">
							<label class="fw-medium mb-0" for="nname2">
								Registered Email :</label
							>
						</b-col>
						<b-col sm="8">
							<span> {{ personal_email }} </span>
						</b-col>
					</b-row>
				</b-col>
				
				<b-col cols="6">
					<b-row class="py-2 custon_border align-items-center">
						<b-col sm="4" class="text-left">
							<label class="fw-medium mb-0" for="nname2">
								Registered Phone :</label
							>
						</b-col>
						<b-col sm="8">
							<span> {{ personal_phone }} </span>
						</b-col>
					</b-row>
				</b-col>
				
				<b-col cols="6">
					<b-row class="py-2 custon_border align-items-center">
						<b-col sm="4" class="text-left">
							<label class="fw-medium mb-0" for="nname2">
								Booking for self :</label
							>
						</b-col>
						<b-col sm="8">
							<span v-if="this.venue_booking_details.booking_for_self == 0">No</span>
							<span v-if="this.venue_booking_details.booking_for_self == 1">Yes</span>
						</b-col>
					</b-row>
				</b-col>
				
				<b-col cols="6" v-if="this.venue_booking_details.booking_for_self == 0">
					<b-row class="py-2 custon_border align-items-center">
						<b-col sm="4" class="text-left">
							<label class="fw-medium mb-0" for="nname2">
								Booking for name :</label
							>
						</b-col>
						<b-col sm="8">
							<span> {{ this.venue_booking_details.booking_for_name }} </span>
						</b-col>
					</b-row>
				</b-col>
				
				<b-col cols="6" v-if="this.venue_booking_details.booking_for_self == 0">
					<b-row class="py-2 custon_border align-items-center">
						<b-col sm="4" class="text-left">
							<label class="fw-medium mb-0" for="nname2">
								Booking for relationship :</label
							>
						</b-col>
						<b-col sm="8">
							<span> {{ this.venue_booking_details.booking_for_relationship }} </span>
						</b-col>
					</b-row>
				</b-col>
				
				<b-col cols="6" v-if="this.venue_booking_details.booking_for_self == 0">
					<b-row class="py-2 custon_border align-items-center">
						<b-col sm="4" class="text-left">
							<label class="fw-medium mb-0" for="nname2">
								Booking for mobile no :</label
							>
						</b-col>
						<b-col sm="8">
							<span> {{ this.venue_booking_details.booking_for_mobile_no }} </span>
						</b-col>
					</b-row>
				</b-col>
				
				<b-col cols="6" v-if="this.special_request != null">
					<b-row class="py-2 custon_border align-items-center">
						<b-col sm="4" class="text-left">
							<label class="fw-medium mb-0" for="nname2"> Special Request:</label>
						</b-col>
						<b-col sm="8">
							{{ this.special_request }}
						</b-col>
					</b-row>
				</b-col>
				
				<b-col cols="6" v-if="add_on.length > 0">
					<b-row class="py-2 custon_border align-items-center">
						<b-col sm="4" class="text-left">
							<label class="fw-medium mb-0" for="nname2"> Add-ons :</label>
						</b-col>
						<b-col sm="8">
							<div v-for="(addon, index) in child_venue_arr.addons_arr" :key="index">
								<p v-if="add_on.indexOf(addon.add_on_id) !== -1">
									<b-img v-if="addon.attachment != null" :src="child_venue_arr.attachment_path + 'add_on/' + JSON.parse(addon.attachment)[0]" width="40px" height="40px"  ></b-img>
									Name : {{ addon.add_on_name }} - Price :
									{{ addon.price }} / -
								</p>
							</div>
						</b-col>
					</b-row>
				</b-col>
				
				<b-col cols="6" v-if="cancel_reason !== null">
					<b-row class="py-2 custon_border align-items-center">
						<b-col sm="4" class="text-left">
							<label
							class="fw-medium mb-0"
							for="nname2"
							style="font-size: 13px"
							>
								Cancel Booking Reason :</label
							>
						</b-col>
						<b-col sm="8">
							<span> {{ cancel_reason }} </span>
						</b-col>
					</b-row>
				</b-col>
				<b-col cols="6" v-if="cancel_reason !== null">
					<b-row class="py-2 custon_border align-items-center">
						<b-col sm="4" class="text-left">
							<label
							class="fw-medium mb-0"
							for="nname2"
							style="font-size: 13px"
							>
								Cancel Booking Date :</label
							>
						</b-col>
						<b-col sm="8">
							<span> {{ cancel_date }} </span>
						</b-col>
					</b-row>
				</b-col>
				<b-col cols="6" v-if="cancel_reason !== null">
					<b-row class="py-2 custon_border align-items-center">
						<b-col sm="4" class="text-left">
							<label
							class="fw-medium mb-0"
							for="nname2"
							style="font-size: 13px"
							>
								Cancel Booking Time :</label
							>
						</b-col>
						<b-col sm="8">
							<span> {{ cancel_time }} </span>
						</b-col>
					</b-row>
				</b-col> 
				
			</b-row>
		</b-card>-->
	</div>
</template>
<script>
	import axios from "axios";
	import helpers from '../../../mixins/helpers'; 
	export default {
		name: "BookingDetails",
		mixins: [helpers],
		data: () => ({
			title: "BookingDetails",
			vendor_name: "",
			vendor_id: "",
			venue_name: "",
			booked_on: "",
			from_date: "",
			to_date: "",
			selected_date: "",
			from_time: "",
			event_name: "",
			booked_shift_type: "",
			to_time: "",
			total_booking_price: "",
			child_venue_name: "",
			add_on: [],
			personal_phone: "",
			personal_email: "",
			customer_name: "",
			billing_first_name: "",
			billing_email: "",
			billing_address: "",
			billing_phone: "",
			cancel_time: "",
			cancel_date: "",
			cancel_reason: "",
			special_request:'',
			child_venue_id:'',
			Book_id:'',
			ref_id:'',
			informations:'',
			guest_capacity:'',
			addOn_total:0,
			add_sub_total:0,
			total_amount:0,
			full_total_amount:0,
			curncy:"",
			taxRequired:"",
			areceipt_no:'' ,
			a_amount:'' ,
			a_pay_mode:'',
			a_pay_date:'' ,
			freceipt_no:'',
			f_amount:'' ,
			f_pay_mode:'',
			f_pay_date:'',
			sreceipt_no:'',
			s_amount:'',
			s_pay_mode:'',
			s_pay_date:'',
			child_venue_arr:{
				addons_arr:[],
				attachment_path:'',
			},
			venue_booking_details:[],
		}),
		
		methods: {
			
			booking_details() {
				var booking_id = this.$route.params.id;
				axios
				.post("/admin/get_historical_detail", {
					booking_id: booking_id,
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							var data = resp.data.booking_detail;
							this.venue_booking_details = data;
							this.vendor_name = data.child_venue_name;
							this.child_venue_name = data.child_venue_name;
						//	this.vendor_id = data.child_venue.get_vendor.vendor_id;
							this.booked_on = data.order_date;
							this.from_date = data.book_date;
							this.selected_date = data.selected_date;
							this.Book_id = data.invoice_no;
							this.ref_id = data.id;
							this.guest_capacity = data.capacity;
							//'base_price', 'add_on', 'discount', 'gst', 'total', 'book_type', 'paymode'
							this.to_date = data.to_date;
							
							if(data.shift==1)
							{
								this.booked_shift_type = "Morning" ;
							}else if(data.shift==2)
							{
								this.booked_shift_type = "Afternoon" ;
							}else if(data.shift==3)
							{
								this.booked_shift_type = "Evening" ;
							}
							this.from_time = data.from_time;
							this.event_name = data.event_name;
							this.to_time = data.to_time;
							this.total_booking_price = data.base_price;

							this.addOn_total = data.add_on;
							this.venue_booking_details.applied_discount = data.discount;
							this.venue_booking_details.payment_mode=data.paymode;
							//this.total_booking_price = data.gst;
							//this.personal_phone = data[0].get_customer.phone;
							//this.personal_email = data[0].get_customer.email;
							//this.customer_name = data[0].get_customer.customer_name;
							//'cust_name', 'cust_address', 'phone', 'email'
							// `areceipt_no`, `a_amount`, `a_pay_mode`, `a_pay_date`, `freceipt_no`, `f_amount`, `f_pay_mode`, `f_pay_date`, 
							//`sreceipt_no`,
							// `s_amount`, `s_pay_mode`, `s_pay_date`,
							this.billing_first_name = data.cust_name;
							this.billing_email = data.email;
							this.billing_address = data.cust_address;
							this.billing_phone = data.phone;
							this.cancel_time = data.cancel_time;
							this.cancel_date = data.cancel_date;
							this.cancel_reason = data.cancel_reason;
							this.special_request = data.special_request;
							this.child_venue_id = data.child_venue_id;

							this.areceipt_no = data.areceipt_no;
							this.a_amount = data.a_amount;
							this.a_pay_mode = data.a_pay_mode;
							this.a_pay_date = data.a_pay_date;
							this.freceipt_no = data.freceipt_no;
							this.f_amount = data.f_amount;
							this.f_pay_mode = data.f_pay_mode;
							this.f_pay_date = data.f_pay_date;
							this.sreceipt_no = data.sreceipt_no;
							this.s_amount = data.s_amount;
							this.s_pay_mode = data.s_pay_mode;
							this.s_pay_date = data.s_pay_date;
							
							
							} else {
							// this.$router.push('')
						}
						
					}
				});
			},
			goBack() {
				this.$router.push("/historical_booking");
			},
			getChildVenueDetails(){
				axios.post("/admin/get_venue_details", {
					child_venue_id: this.child_venue_id,
				})
				.then((resp) => {
					if (resp.data.status_code == 200 && resp.data.status) {
						this.child_venue_arr.addons_arr = resp.data.child_venue_details[0].add_ons;
						this.child_venue_arr.attachment_path = resp.data.child_venue_details[0].attachment_path;
					}
				});
			},
			editbooking()
			{
				var booking_id = this.$route.params.token;
				this.$router.push("/edit-booking/"+booking_id);
			},
			date_format:function(date)
			{
				var d1 = new Date(date);
				var ms = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
				return d1.getDate() + '' + this.getDateOrdinal(d1) +' ' + ms[d1.getMonth()] + ' ' + d1.getFullYear();
				
				/* var myDate = new Date(new Date(date).getTime()+(1*24*60*60*1000));
					var d = new Date(myDate),
					month = '' + (d.getMonth() + 1),
					day = '' + d.getDate(),
					year = d.getFullYear();
					
					if (month.length < 2) 
					month = '0' + month;
					if (day.length < 2) 
					day = '0' + day; 
					
					const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
					"Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
					]; 
					const New_month_names=  monthNames[month-1];
					
				return [day+'th',New_month_names,year].join(' '); */
			},
			getDateOrdinal(date) {
				let day = date.getDate();
				if (day > 3 && day < 21) return 'th';
				switch (day % 10) {
					case 1:  return "st";
					case 2:  return "nd";
					case 3:  return "rd";
					default: return "th";
				}
			},
			

			autocalculation(amount)
			{
				this.taxper ='18%';
				/* let add_amount=0;
				for(var i=0;i<this.child_venue_arr.addons_arr.length;i++)
				{
					if(this.add_on.indexOf(this.child_venue_arr.addons_arr[i].add_on_id) !== -1)
					{
						add_amount+=this.child_venue_arr.addons_arr[i].price;
					}
				} */
				//this.addOn_total=add_amount;
				this.add_sub_total=this.addOn_total+amount;
				
				if(this.venue_booking_details.applied_discount != '' && this.venue_booking_details.applied_discount >= 0 && amount >= this.venue_booking_details.applied_discount){
					
					this.after_discount = parseInt(this.add_sub_total) - parseInt(this.venue_booking_details.applied_discount);
				
				}
                else
				{
					this.after_discount = parseInt(this.add_sub_total);
				}

				if(this.taxRequired=='yes')
				{
					this.afterTax=this.after_discount*18/100;
				}
				else
				{
					this.afterTax=0;
				}


				this.total_amount=this.after_discount+this.afterTax; //inclusive tax

				this.full_total_amount=parseInt(this.total_amount);
				
				return amount;
			},
			vendor_check()
			{
				axios.get("/admin/get_profile").then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
                            this.curncy= resp.data.user_details.currencys;
							if(resp.data.user_details.gst_no==null)
							{
								this.taxRequired='no';
							}
							else
							{
								this.taxRequired='yes';
							}
						}
					}
				});
			},
			
			
		},
		mounted() {
			this.booking_details();
			this.vendor_check();
			this.getProfile();
			document.documentElement.scrollTop = 0;
		},
	};
</script>
<style scoped>
	.custom-amount-summary
	{
	list-style-type: none;
    margin-left: -40px;
	}
	.fs-12
	{
	font-size: 10px;
	}
	.extradesign
	{
	padding: 4px 4px 2px;
    box-shadow: 0px 0px 5px 0px;
    border-radius: 7px;
	height: 50px;
	margin-bottom: 11px;
	}
	
	.ml--3
	{
	margin-left: -3%;
	}.ml--7
	{
	margin-left: -7%;
	}
	.mt--3
	{
	margin-top: -3%;
	}
</style>